.list-group {
    max-height: 40em;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
}

.img {
    max-width: 100px;
}

.btn-primary {
    margin-top: 1px;
}

.list-group-item.active {
    z-index: 2;
    background-color: #d1d1d1;
    border-color: #d1d1d1;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    height: 50px;
    width: 50px;
    outline: black;
    background-size: 100%, 100%;
    border-radius: 50%;
    border: 1px solid black;
    background-image: none;
    background: black;
}

.carousel-control-next-icon:after {
    content: '>';
    font-size: 30px;
    color: white;
}

.carousel-control-prev-icon:after {
    content: '<';
    font-size: 30px;
    color: white;
}

.tooltip {
    visibility: hidden;
    width: 100px;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    margin-left: -50px;
    opacity: 0;
    transition: opacity 0.3s;
}

.toggleButtonsRow {
    margin-top: +1rem !important;
    margin-left: +0.05rem !important;
    margin-right: +0.15rem !important;
    padding-bottom: 10px;
    cursor: pointer;
}

.toggleButtonDiv {
    margin-left: -0.rem !important;
    margin-right: -0.rem !important;
    padding-right: -0.0rem !important;
    margin-top: -15px;
    margin-bottom: -20px;
}

.toggleButton,
.toggleButton.focus,
.toggleButton.active,
.toggleButton.disable {
    background: white !important;
    color: #1640a8 !important;
    outline: none !important;
    box-shadow: none !important;
    border: none !important;
    cursor: pointer;
}

.downloadButton, .downloadButton:hover, .downloadButton:active, .downloadButton:focus {
    margin-top: +1.0rem !important;
    color: #1640a8;;
    background: #FFFFFF;
}

.container {
    max-width: '100%'
}  


.tabsRow{
    margin-top: +0.5rem !important;
    display: block;

}

.tab-content{
    margin-top: +0.5rem !important;
    box-shadow: none;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #0065FF;
    font-weight: 500;
}

.nav-tabs .nav-link{
    color: black;
    font-weight: 500;
}

.lateral-inner{
    color: black;
    font-weight: 500;
    padding-left: 0.0rem !important;
    padding-right: 0.0rem !important;
    text-align: center;
}

.inner-info{
    margin-top: 5.5rem !important;
}

.inside-lateral-inner{
    color: #0065FF;
}
