h2 {
	font-family: "Steppe Regular", sans-serif;
}

.navbar{
	box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
	padding: 0rem 0.5rem;
}


  .btn-primary {
	background-color: #1640a8;
	border-color: #1640a8;
	margin-right: 5px;
  }

a.btn.btn-primary {
    color: white;
}

.user-logo{
	margin-left: 2.5rem !important;
	border-left: 2px solid black;
	padding-left: 2.0rem !important;
}
