.dropzone {
  text-align: center;
  padding: 20px;
  border: 3px dashed #9ca1d9;
  background-color: rgb(238,241,254);
  color: #9ca1d9;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.dropzone-text-para {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.close {
  margin-top:-0.5em;
  cursor: pointer;
  margin-bottom:0.25em;
}

