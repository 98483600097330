body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar {
	background: white;
  }



.bg-custom-button {
  background-color: #1F2833;
  border-color: #45A293;
  border: 3px solid-transparent;
  color: #45A293;
  border-radius: 100px;
}


.btn-primary {
  background-color: #1640a8;
  border-color: #1640a8;
  margin-right: 5px;
  color:white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  
}

.btn-outline-primary {
  border-color: #1640a8;
  margin-right: 5px;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;

}

.btn-outline-danger {
  border-color: #A87E16;
  margin-right: 5px;
  color: #A87E16;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.btn-primary:hover, .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle{
  border-color: #0d2276;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.btn-outline-primary:hover, .btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active,
.show>.btn-outline-primary.dropdown-toggle{
  background-color: #0d2276;
  border-color: #0d2276;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;

}

.btn-outline-danger:hover, .btn-outline-danger:not(:disabled):not(.disabled).active, .btn-outline-danger:not(:disabled):not(.disabled):active,
.show>.btn-outline-danger.dropdown-toggle{
  background-color: #76610D;
  border-color: #76610D;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;

}

.btn-primary.disabled, .btn-primary:disabled{
  background-color: rgb(166,173,185);
  border-color: rgb(166,173,185);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;

}

.sc-bdnxRM { 
  margin-bottom: -10px;
  padding-bottom: 0px;
  transform: scale(0.7);
}

.sc-bdnxRM.hfKRmD {

  padding: 0;
  padding-bottom: 0;
}

.mt-5, .my-5 {
  margin-top: 0.rem!important;
}

.mt-4, .my-4 {
  margin-top: 1.5rem!important;
}



.card {

  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.tab-content {

  box-shadow: rgba(60, 64, 67, 0.3) 0px 0px 0px 0px, rgba(60, 64, 67, 0.15) 0px 0px 0px 0px;
}

.list-group {

  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.tab-content {

  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}


